<template>
  <q-page class="q-pa-sm">
    <iframe
      width="100%"
      height="100%"
      seamless
      frameborder="0"
      scrolling="no"
      src="https://script.google.com/macros/s/AKfycbwb-LxEZSv0mJp7LnhpyqtzKXu0r6VW9uJBTH3_85RRv3EiRwE/exec?type=charts"
      style="height: calc(100vh - 75px)"
    ></iframe>

    <!-- <q-card flat>
      <q-card-section class="row">
        <q-card class="col-12 col-md-6">
          <q-card-section> </q-card-section>
        </q-card>
      </q-card-section>
    </q-card> -->

    <!-- src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTGOEZh5STivIJmTML382yD_ntGjPFbtQeULnD9gURvnY53X5yqSGGB0VLCjKIBo_eqJUmABvoLJylK/pubchart?oid=673171375&amp;format=image" -->
  </q-page>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import api from "../api/index";
import { useStore } from "vuex";
import axios from "axios";

export default defineComponent({
  name: "Dashboard",
  setup() {
    const store = useStore();
    const SheetId = store.getters["SheetId"];
    const key = store.getters["Key"];

    const columns = ref([
      { label: "Time", align: "left" },
      { label: "Category", align: "left" },
      { label: "Amount", align: "left" },
      { label: "Notes", align: "left" },
    ]);

    const items = ref([]);

    const ReadData = () => {
      api.ReadRows(SheetId, key).then((r) => {
        console.log(r.data.values);
        items.value = [...r.data.values];
      });
    };

    const TestPost = () => {
      var x = {
        data: "jared",
      };

      axios
        .get(
          `https://script.google.com/macros/s/AKfycbwb-LxEZSv0mJp7LnhpyqtzKXu0r6VW9uJBTH3_85RRv3EiRwE/exec?out=text&method=test&data=${JSON.stringify(
            x
          )}`
        )
        .then((response) => {
          console.warn("test get", response);
        });
    };

    onMounted(() => {
      store.dispatch("UpdateHeader", "Dashboard");
      ReadData();
      setTimeout(() => {
        TestPost();
      }, 1000);
    });

    return {
      items,
      columns,
    };
  },
});
</script>
